import React, { useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Slider from "react-slick";

import Video from "@components/Video";
import Layout from "@components/Layout";
import ArrowLeftCircle from "@assets/images/icon/arrow-circle-left-white.svg";
import css from "@cssmodules/order.module.scss";

import { order } from "@utils/static-data";

const CaraOrder = () => {
  const [activeOrder, setActiveOrder] = useState(order[0]);

  const sliderRef = useRef();

  const { desktopBG, mobileBG } = useStaticQuery(graphql`
    query CaraOrder {
      desktopBG: file(
        relativePath: { eq: "bg/desktop/bg-hero-cara-order.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
            src
            srcSet
            base64
          }
        }
      }
      mobileBG: file(relativePath: { eq: "bg/mobile/bg-hero-cara-order.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
            src
            srcSet
            base64
          }
        }
      }
    }
  `);

  const bg = [
    mobileBG.childImageSharp.fluid,
    {
      ...desktopBG.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: css.heroSlider,
  };

  return (
    <Layout noFooter>
      <div className={css.img}>
        <Img
          fluid={bg}
          alt="layanan-gratis"
          style={{ width: 100 + "%", height: 100 + "%" }}
        />
      </div>
      <Slider {...sliderSettings} ref={sliderRef}>
        {/* HEADER */}
        <section className={css.wrapper}>
          <div className={css.hero}>
            <div className={css.contents}>
              <div className={css.youtube}>
                <Video
                  link={"https://www.youtube.com/embed/tVRvFQIMcaA"}
                  quality={"sd"}
                  imgStyle={{
                    borderRadius: 0,
                  }}
                />
              </div>
              <div className={css.titles}>
                <h1>
                  <p>4 Cara Mudah</p>
                  <p>Pesan Jaket</p>
                </h1>
                <div className={css.subTitle}>
                  <p>Selalu memudahkan dan</p>
                  <p>menguntungkan anda</p>
                </div>
              </div>
            </div>

            <div className={css.nextSlide} onClick={nextSlide}>
              <img src={ArrowLeftCircle} className={css.down} />
              <span>Lihat semua step cara order</span>
            </div>
          </div>
        </section>

        <section className={css.wrapper}>
          {/* DETAILS */}
          <div className={css.section}>
            <div className={css.title}>
              <h1>
                Penjelasan <span>Cara Order</span>
              </h1>
            </div>
            <div className={css.penjelasan}>
              <div className={css.list}>
                <h6>Pilih step sesuai urutan</h6>
                <div className={css.pointWrapper}>
                  {order.map((object, index) => (
                    <div
                      key={index}
                      className={activeOrder === object ? css.active : ""}
                      onClick={() => setActiveOrder(object)}
                    >
                      <span>{index + 1}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className={css.details}>
                <h5>
                  {activeOrder.title.map((text, index) => (
                    <span key={index}>{text}&nbsp;</span>
                  ))}
                </h5>

                {activeOrder.details.map((detail, index) => (
                  <p
                    key={index}
                    dangerouslySetInnerHTML={{ __html: detail }}
                  ></p>
                ))}
              </div>
            </div>

            <div className={css.nextSlide} onClick={prevSlide}>
              <img src={ArrowLeftCircle} className={css.up} />
              <span className={css.back}>Kembali untuk melihat video cara order</span>
            </div>
          </div>
        </section>
      </Slider>
    </Layout>
  );
};

export default CaraOrder;
